import React from "react";
import { graphql, Link } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import StructuredTextRenderer from "components/StructuredTextRenderer/StructuredTextRenderer";
import * as legalStyling from "./legalPage.module.scss";

const LegalPage = ({ data, pageContext }) => {

  const structuredText = data.datoCmsLegalpage.text;
  const seo = data.datoCmsLegalpage.seoMetaTags;
  const allLegalPages = pageContext.allLegalPages;
  const visbleForNav = allLegalPages.filter(p => !p.hideNavigation === true)

  return (
    <>
      <HelmetDatoCms seo={seo} />
      <div className={`layout-row section--header pt-4 `}>
        <p className="text8 inline-element mt-7">Legal</p>
      </div>
      <div className="layout-row relative">
        <div className="hide-from-s">
          <ul className={`${legalStyling.borderTop} icon-list`}>
            {visbleForNav.map((page) => {
              return (
                <li
                  className={`mb-0 text4 ${legalStyling.link} `}
                  key={page.title}
                >
                  <Link className="text-decoration-none" to={`/${page.slug}`}>
                    {page.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="row">
          <div className="col sm-3">
            <nav className={`hide-to-s`}>
              <ul className={legalStyling.legalContainer}>
                {visbleForNav.map((page) => {
                  return (
                    <li
                      className={`${legalStyling.link} text3 menu__item`}
                      key={page.title}
                    >
                      <Link to={`/${page.slug}`}>{page.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
          <div className={`col sm-8 section ${legalStyling.legalContainer}`}>
            <StructuredTextRenderer
              data={structuredText}
              isParagraphClassName="mb-7 subtle"
              isHeadingClassName="mb-5"
              isListItemClassName="mb-7 subtle"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query ($title: String) {
    datoCmsLegalpage(title: { eq: $title }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      text {
        value
        blocks {
          __typename
          ...TableReference
        }
      }
    }
  }
`;

export default LegalPage;